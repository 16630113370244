import React from 'react';
import SliderHowItWorks from "../../AppCustomizedComponent/SliderHowItWorks";
import {stringsTranslate} from "../../../locales/CustomTranslater";
import {appWords} from "../../../config/Constants";



export default function HowItWorksSectionWhoIsKnaek() {

    const initStrings = {
        "headingText": "stringsTranslate(appWords.go_through_these_steps)",
        "workData": [
            {
                "id": 1,
                "image": "/assets/images/stap-04.gif",
                "title": stringsTranslate(appWords.step) + ' 1',
                "content": stringsTranslate(appWords.how_it_works_step_1_content)
            },
            {
                "id": 2,
                "image": "/assets/images/stap-05.gif",
                "title": stringsTranslate(appWords.step) + ' 2',
                "content": stringsTranslate(appWords.how_it_works_step_2_content)
            },
            {
                "id": 3,
                "image": "/assets/images/stap-06.gif",
                "title": stringsTranslate(appWords.step) + ' 3',
                "content": stringsTranslate(appWords.how_it_works_step_3_content)
            }
        ]
    }


    return (
        <section className="section ptb_50 pb-5">
            <div className="container">
                <div className="row d-none d-md-none d-lg-block">
                    <div className="col-12 app-works-steps">
                        <div className="row">
                            <div className="col-md-4 col-sm-10 mb-5 pb-5 d-flex align-items-start">
                                {/* Single Work */}
                                <div className="text-center ">
                                    {/* Work Icon */}
                                    <div className="work-icon">
                                        <img src={initStrings.workData[0].image} width={250}  alt=""/>
                                    </div>
                                    <h4 className="text-knaek-primary">{initStrings.workData[0].title}</h4>
                                    <p className="text-knaek-sub">{initStrings.workData[0].content}</p>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-10 d-flex align-items-center">
                                {/* Single Work */}
                                <div className=" text-center">
                                    {/* Work Icon */}
                                    <div className="work-icon">
                                        <img src={initStrings.workData[1].image} width={250}  alt=""/>
                                    </div>
                                    <h4 className="text-knaek-primary">{initStrings.workData[1].title}</h4>
                                    <p className="text-knaek-sub">{initStrings.workData[1].content}</p>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-10 mb-5 pb-5 d-flex align-items-start">
                                {/* Single Work */}
                                <div className=" text-center ">
                                    {/* Work Icon */}
                                    <div className="work-icon">
                                        <img src={initStrings.workData[2].image} width={250} alt=""/>
                                    </div>
                                    <h4 className="text-knaek-primary">{initStrings.workData[2].title}</h4>
                                    <p className="text-knaek-sub">{initStrings.workData[2].content}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row d-block d-lg-none">
                    <SliderHowItWorks data={initStrings.workData}/>
                </div>
            </div>
        </section>
    );
}
