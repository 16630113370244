import React, { useState} from 'react';
import {Modal} from "react-bootstrap";
import KnaekPrimaryButton from "./KnaekPrimaryButton";
import {createSnipTechPayoutCall} from "../../api/ApiCalls";
import {hasAValue} from "../../uitls/SharedFunctions";
import {stringsTranslate} from "../../locales/CustomTranslater";
import {appWords} from "../../config/Constants";
import {afterPayoutRequestAlertFunction} from "../../uitls/AlertFunctions";

export default function PayoutModalComponent({onClose, sniptechBalance}) {

    const [formData, setFormData] = useState({
        bank_account_number: '',
        accountHolderName: '',
        accountHolderCity: 'Amsterdam',
        accountHolderDateOfBirth: '1970-10-10'
    });
    const [errorMessage, setErrorMessage] = useState(undefined);

    const initString = {
        payout: (stringsTranslate(appWords.payout)).toString().toUpperCase(),
        iban: (stringsTranslate(appWords.iban)).toString().toUpperCase(),
        fullName: stringsTranslate(appWords.full_name),
        payoutRequested: stringsTranslate(appWords.payoutRequested),
        attention: stringsTranslate(appWords.attention),
        yourBalanceWillBePaid: stringsTranslate(appWords.yourBalanceWillBePaid),
    }

    return (
        <Modal className="modal fade radius-15 mt-4" size={"lg"} onHide={onClose} show={true}>
            <div className="radius-15" role="document">
                <div className="radius-15">
                    <div className={"radius-15 pt-3 bg-light"}>
                        <div className="card-header bg-light border-0 d-flex justify-content-end cursor-pointer"
                             onClick={onClose}>
                            <svg className=" mt-3 mr-3" width="15" height="15" viewBox="0 0 32 32">
                                <path fill="black"
                                      d="M31.29,27.48a2.7,2.7,0,0,1-3.81,3.81L16,19.83,4.52,31.29A2.7,2.7,0,0,1,.71,27.48L12.17,16,.71,4.52A2.7,2.7,0,0,1,4.52.71L16,12.17,27.48.71a2.7,2.7,0,0,1,3.81,3.81L19.83,16Z"/>
                            </svg>
                        </div>
                        <div className="modal-body radius-15">
                            <div className="card border-top border-0">
                                <div className="card-header bg-light border-0 d-flex justify-content-center">
                                    <h1 className="card-title">{initString.payout}</h1>
                                </div>

                                <form action={submitForm}>
                                    <ul className="card-body bg-light pb-0">
                                        {hasAValue(errorMessage) &&
                                            <label className={"my-3 ml-2 text-danger"}>{errorMessage}</label>}

                                        <li className="list-group-item radius-15 border-0 my-2">
                                            <label className="knaek-form-label"
                                                   htmlFor="iban">{initString.iban} *</label>
                                            <input
                                                type="text"
                                                id="iban"
                                                className="form-control bg-transparent shadow-none p-0"
                                                style={{height: "30px"}}
                                                name="iban"
                                                value={formData.bank_account_number}
                                                onChange={(e) => updateFormData({bank_account_number: e.target.value?.replace(/ /g, '')})}
                                            />
                                        </li>
                                        <li className="list-group-item radius-15 border-0 my-2">
                                            <label className="knaek-form-label"
                                                   htmlFor="fullName">{initString.fullName} *</label>
                                            <input
                                                type="text"
                                                id="fullName"
                                                className="form-control bg-transparent shadow-none p-0"
                                                style={{height: "30px"}}
                                                name="fullName"
                                                value={formData.accountHolderName}
                                                onChange={(e) => updateFormData({accountHolderName: e.target.value})}
                                            />
                                        </li>
                                        <li className="bg-light d-flex flex-wrap justify-content-center align-items-center">
                                            <div
                                                className={" border-0 order-0 order-lg-1 d-flex justify-content-center pt-3 pb-3"}>
                                                <KnaekPrimaryButton
                                                    title={initString.payout}
                                                    className={" mt-2"}
                                                    onClick={submitForm}
                                                />
                                            </div>
                                        </li>
                                    </ul>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );

    function updateFormData(value = {}) {
        setFormData({...formData, ...value});
        setErrorMessage(undefined);
    }

    function submitForm(e) {
        e.preventDefault();
        if (Object.values(formData).some(value => !value)) {
            setErrorMessage(initString.claimFormMandatoryFieldsErrorMessage);
            return;
        }
        onClose();
        if (sniptechBalance > 0) {
            createSnipTechPayoutCall(formData);
        }
        afterPayoutRequestAlertFunction(initString.attention, initString.yourBalanceWillBePaid)
        // successAlertFunction(initString.payout, initString.payoutRequested);
    }
}
