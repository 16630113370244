import React, {useState, useEffect} from 'react';
import {Animated} from "react-animated-css";
import CancellingMembershipFlowStep2 from "./CancellingMembershipFlowStep3";
import CancellingMembershipFlowStep1 from "./CancellingMembershipFlowStep1";


export default function CancellingMembershipSections() {

    const [currentStep, setCurrentStep] = useState(1);
    const [hasSubscription, setHasSubscription] = useState(false);
    const [email, setEmail] = useState(undefined);
    const [phone_number, setPhone_number] = useState(undefined);
    const [iban, setIban] = useState(undefined);
    const [birthday, setBirthday] = useState(undefined);

    useEffect(() => {

    }, [])

    return (
        <div className="homepage-4">
            <div className="main">
                <div className="container">
                    <div className="row justify-content-center">
                        {currentStep === 1 && <Animated className={"col-12 col-lg-8 bg-white h-100vh"}
                                  animationIn="bounceInLeft"
                                  animationOut="fadeOut" isVisible={(currentStep === 1)} animationInDuration={1000}
                                  animationInDelay={100}>
                            <CancellingMembershipFlowStep1 currentStep={currentStep} setCurrentStep={(val)=> setCurrentStep(val)}
                                                           setHasSubscription={(val)=> setHasSubscription(val)}
                                                           email={email} birthday={birthday}
                                                           setEmail={val => setEmail(val)}
                                                           phone_number={phone_number} iban={iban}
                                                           setPhone_number={val=> setPhone_number(val)}
                                                           setBirthday={val=> setBirthday(val)}
                                                           setIban={val=> setIban(val)}
                            />
                        </Animated>}

                        {currentStep === 2 && <Animated className={"col-12 col-lg-8 bg-white h-100vh"}
                                  animationIn="bounceInLeft"
                                  animationOut="fadeOut" isVisible={(currentStep === 2)} animationInDuration={1000}
                                  animationInDelay={100}>
                            <CancellingMembershipFlowStep2 currentStep={currentStep} setCurrentStep={(val)=> setCurrentStep(val)}
                                                           hasSubscription={hasSubscription}/>
                        </Animated>}

                    </div>
                </div>
            </div>
        </div>
    );


}
