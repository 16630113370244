import React, {useEffect, useState} from 'react';
import KnaekPrimaryButton from "../../AppComponents/KnaekPrimaryButton";
import {useDispatch} from "react-redux";
import {stringsTranslate} from "../../../locales/CustomTranslater";
import {appWords} from "../../../config/Constants";
import {getAllSnipTechOnlineDiscountsCall} from "../../../api/ApiCalls";
import {
    updateOnlineDiscountsSnipTechRS,
    updateSnipTechStoresCountRS
} from "../../../redux/reduxActions/reduxStoreActions";
import SliderSniptechOnlineDiscounts from "../../AppCustomizedComponent/SliderSniptechOnlineDiscounts";
import {  getCurrentYearBasedOnMonth } from '../../../uitls/SharedFunctions';
import { useHandleLinkClick } from '../../../hooks/useHandleLinkClick';





export default function OnlineSectionHome() {


    const initStrings = {
        heading: stringsTranslate(appWords.online_discounts_by_4000_webshops) ,
        headingText: stringsTranslate(appWords.with_knaek_cashback_you_get_up),
        headingLink: stringsTranslate(appWords.here),
        subheadingText1: stringsTranslate(appWords.check),
        subheadingText2: stringsTranslate(appWords.check_how_you_can_get_cashback),
        betweenContent: stringsTranslate(appWords.homepage_online_discount_between_text_1),
        betweenContent1: stringsTranslate(appWords.homepage_online_discount_between_text_2),
        betweenContent2: stringsTranslate(appWords.homepage_online_discount_between_text_3),
        betweenContentAmount: stringsTranslate(appWords.homepage_online_discount_between_text_amount),
        btnContent1: stringsTranslate(appWords.show_webshops),
        btnContent2: stringsTranslate(appWords.get_to_know_more),


    }

    const [filters, setFilters] = useState({});
    const [items, setItems] = useState([]);
    const [nextPage, setNextPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1000);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const handleLinkClick = useHandleLinkClick();

    useEffect(() => {
        getSnipTechOnlineDiscounts(1)

    },[]);

    return (
        <div id="screenshots" className="section bg-gray homepage-online-section py-lg-5 py-0">
            <div className="container mt-5">
                <div className="row justify-content-center">
                    <div className="col-sm-12 col-md-12 col-lg-4 pt-5 mt-5">
                        <section className="row justify-content-center justify-content-lg-start">
                            {/* Section Heading */}
                            <div className="col-12 text-center text-lg-left">
                                <h2 className="">{initStrings.heading}</h2>

                                <h5 className="mt-4">{initStrings.headingText}</h5>
                                <h6 className={"font-weight-normal"}>
                                    {initStrings.subheadingText1}
                                    <a className={"how-it-works-cb-text cursor-pointer scroll cursor-pointer"} href={"#how-it-works-cb"}>
                                            {initStrings.headingLink}
                                        </a>
                                        {initStrings.subheadingText2}
                                </h6>
                            </div>
                            <div className="col-sm-10 col-md-6 col-lg-10 mt-3">
                                <KnaekPrimaryButton title={initStrings.btnContent1}
                                                    onClick={()=> handleLinkClick('/cashback')}
                                                    className={"mt-4 mb-5 border-0 d-none d-sm-none d-md-block"}
                                />
                            </div>
                        </section>
                    </div>


                    <div className="col-sm-12 col-md-12 col-lg-8 my-5 pb-5">
                        <div className="row">
                            <SliderSniptechOnlineDiscounts data={items}/>
                        </div>
                    </div>

                    <div
                        className="between-element2-homepage col-11 d-flex flex-wrap align-items-center justify-content-center text-center m-5 p-1">
                        <span className={"text-white h4 p-2"}> {initStrings.betweenContent}
                            <a className={"h2 font-weight-bold"}> {getCurrentYearBasedOnMonth(2)} </a>
                            {initStrings.betweenContent1}
                             <span className={"h2 font-weight-bold"}>  {initStrings.betweenContentAmount} </span>
                            {initStrings.betweenContent2}.</span>
                    </div>
                </div>
            </div>
        </div>
    );




    function getFilter() {
        const categoryIds = filters.category?.ids ?? [];
        const weightFilter = filters.webWeightLT ? `webWeightLT=${filters.webWeightLT}` : 'webWeightLT=70';
        const apiFilters = [weightFilter, ...categoryIds.map(id => `categoryId=${id}`)];

        return apiFilters.join('&');
    }
    function getSnipTechOnlineDiscounts(page = 1) {
        setIsLoading(true);

        getAllSnipTechOnlineDiscountsCall(page, 10, getFilter())
            .then(result => {
                setItems(result.items);
                dispatch(updateSnipTechStoresCountRS(result.count));
                dispatch(updateOnlineDiscountsSnipTechRS(result.items));
            })
            .catch(error => {
                // Handle the error if necessary
            })
            .finally(() => {
                setIsLoading(false);
            });
    }
}
