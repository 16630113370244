import React from 'react';

export default function ReviewsHeader({switchLanguage}) {


    return (
        <div class="main-loader">
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 280 250" preserveAspectRatio="xMidYMid" style={{display: "block", shapeRendering: "auto", background: "transparent"}} width="280" height="250">
                <g style={{transformOrigin:"140px 125px", transform:"scale(1)" }} class="ld-text">
                    <g transform="translate(140,125)">
                        <g transform="scale(1.0000) translate(0,0)">
                            <g class="path" style={{opacity: "1", transformOrigin:"-81.76px -6.8px; transform: matrix3d(0.91, 0, 0, 0, 0, 0.91, 0, 0, 0, 0, 0.91, 0, 0, 0, 0, 1)", animation:"1s linear -0.55s infinite normal forwards running animate-cnm5ie3c5cs", transformBox: "view-box"}}>
                                <path d="M7.04 40.95L7.04-13.61L13.76-13.61L13.76 11.59L36.64-13.61L45.04-13.61L24.08 9.27L48.24 40.95L39.84 40.95L19.44 14.23L13.76 20.47L13.76 40.95L7.04 40.95" style={{fill: "rgb(255, 255, 255)"}} transform="translate(-109.400,-20.473)"></path>
                            </g>
                            <g class="path" style={{opacity: "1", transformOrigin:"-39.88px -0.04px; transform: matrix3d(0.91, 0, 0, 0, 0, 0.91, 0, 0, 0, 0, 0.91, 0, 0, 0, 0, 1)", animation:"1s linear -0.44s infinite normal forwards running animate-cnm5ie3c5cs", transformBox: "view-box"}}>
                                <path d="M53.52 40.95L53.52 8.71Q53.60 7.03 53.12 1.43L53.12 1.43L59.04 0.63L59.92 5.75Q62.32 4.15 64.68 2.95Q67.04 1.75 69.52 0.83Q72-0.09 74.32-0.09L74.32-0.09Q77.60-0.09 79.80 0.67Q82 1.43 83.36 3.19Q84.72 4.95 85.32 7.83Q85.92 10.71 85.92 15.03L85.92 15.03L85.92 40.95L79.52 40.95L79.52 15.75Q79.52 13.35 79.44 11.47Q79.36 9.59 78.76 8.27Q78.16 6.95 76.92 6.23Q75.68 5.51 73.44 5.51L73.44 5.51Q71.36 5.51 69.04 6.43Q66.72 7.35 64.48 8.55Q62.24 9.75 59.92 11.35L59.92 11.35L59.92 40.95L53.52 40.95" style={{fill: "rgb(255, 255, 255)"}} transform="translate(-109.400,-20.473)"></path>
                            </g>
                            <g class="path" style={{opacity: "1", transformOrigin:"0.76px 0.48px; transform: matrix3d(0.91, 0, 0, 0, 0, 0.91, 0, 0, 0, 0, 0.91, 0, 0, 0, 0, 1)", animation:"1s linear -0.33s infinite normal forwards running animate-cnm5ie3c5cs", transformBox: "view-box"}}>
                                <path d="M119.60 31.59L119.60 22.47Q118 22.55 116.56 22.63Q115.12 22.71 113.72 22.83Q112.32 22.95 111.20 23.11L111.20 23.11Q105.44 23.83 103.04 25.63Q100.64 27.43 100.64 30.79L100.64 30.79Q100.64 33.59 102.36 34.99Q104.08 36.39 106.88 36.39L106.88 36.39Q110.72 36.39 113.72 35.07Q116.72 33.75 119.60 31.59L119.60 31.59 zM120.32 41.59L119.52 37.19Q115.76 39.35 112.52 40.67Q109.28 41.99 105.52 41.99L105.52 41.99Q100.16 41.99 97 39.39Q93.84 36.79 93.84 30.79L93.84 30.79Q93.84 27.67 95.44 25.35Q97.04 23.03 99.64 21.47Q102.24 19.91 105.52 19.03Q108.80 18.15 112.24 17.91L112.24 17.91Q113.68 17.75 116 17.63Q118.32 17.51 119.60 17.43L119.60 17.43Q119.60 14.63 119.44 12.43Q119.28 10.23 118.48 8.67Q117.68 7.11 116.12 6.31Q114.56 5.51 111.68 5.51L111.68 5.51Q109.36 5.51 107.20 5.79Q105.04 6.07 103.36 6.39L103.36 6.39Q101.36 6.79 99.68 7.27L99.68 7.27L98.40 1.75Q100.40 1.27 102.72 0.87L102.72 0.87Q104.72 0.47 107.36 0.19Q110-0.09 113.04-0.09L113.04-0.09Q117.12-0.09 119.64 1.11Q122.16 2.31 123.56 4.35Q124.96 6.39 125.48 8.99Q126 11.59 126 14.47L126 14.47L126 33.67Q126 34.79 126.04 36.03Q126.08 37.27 126.20 38.47Q126.32 39.67 126.48 40.95L126.48 40.95L120.32 41.59" style={{fill: "rgb(255, 255, 255)"}} transform="translate(-109.400,-20.473)"></path>
                            </g>
                            <g class="path" style={{opacity: "1", transformOrigin:"42.64px 0.48px; transform: matrix3d(0.91, 0, 0, 0, 0, 0.91, 0, 0, 0, 0, 0.91, 0, 0, 0, 0, 1)", animation:"1s linear -0.22s infinite normal forwards running animate-cnm5ie3c5cs", transformBox: "view-box"}}>
                                <path d="M165.84 34.63L167.04 39.99Q165.20 40.55 163.24 40.95Q161.28 41.35 158.92 41.67Q156.56 41.99 154 41.99L154 41.99Q150 41.99 146.56 40.87Q143.12 39.75 140.56 37.27Q138 34.79 136.52 30.83Q135.04 26.87 135.04 21.11L135.04 21.11Q135.04 15.91 136.48 11.95Q137.92 7.99 140.44 5.31Q142.96 2.63 146.36 1.27Q149.76-0.09 153.76-0.09L153.76-0.09Q157.68-0.09 160.56 1.43Q163.44 2.95 165.32 5.55Q167.20 8.15 168.12 11.63Q169.04 15.11 169.04 18.95L169.04 18.95L167.84 22.47L142 22.47Q142 24.95 142.64 27.43Q143.28 29.91 144.84 31.91Q146.40 33.91 148.92 35.15Q151.44 36.39 155.20 36.39L155.20 36.39Q157.60 36.39 159.60 36.11Q161.60 35.83 163.08 35.47Q164.56 35.11 165.84 34.63L165.84 34.63 zM142.24 17.35L162.40 17.35Q162.40 16.79 162.16 14.87Q161.92 12.95 161.04 10.87Q160.16 8.79 158.32 7.15Q156.48 5.51 153.44 5.51L153.44 5.51Q150.08 5.51 147.92 6.91Q145.76 8.31 144.48 10.23Q143.20 12.15 142.72 14.15Q142.24 16.15 142.24 17.35L142.24 17.35" style={{fill: "rgb(255, 255, 255)"}} transform="translate(-109.400,-20.473)"></path>
                            </g>
                            <g class="path" style={{opacity: "1", transformOrigin: "85.2px -9.84px", transform: "matrix3d(0.91, 0, 0, 0, 0, 0.91, 0, 0, 0, 0, 0.91, 0, 0, 0, 0, 1)", animation: "1s linear -0.11s infinite normal forwards running animate-cnm5ie3c5cs", transformBox: "view-box"}}>
                                <path d="M177.76 40.95L177.76-18.97L184.16-19.69L184.16 16.39L202 0.71L210.80 0.71L193.36 15.99L211.44 40.95L203.36 40.95L188.64 20.07L184.16 23.99L184.16 40.95L177.76 40.95" style={{fill: "rgb(255, 255, 255)" }} transform="translate(-109.400,-20.473)"></path>
                            </g>
                            <g class="path" style={{opacity: "1", transformOrigin: "111.6px 15.76px", transform: "matrix3d(0.91, 0, 0, 0, 0, 0.91, 0, 0, 0, 0, 0.91, 0, 0, 0, 0, 1)", animation:" 1s linear 0s infinite normal forwards running animate-cnm5ie3c5cs", transformBox: "view-box"}}>
                                <path d="M216.16 36.23L216.16 36.23Q216.16 34.31 217.52 32.91Q218.88 31.51 220.88 31.51L220.88 31.51Q222.96 31.51 224.40 32.91Q225.84 34.31 225.84 36.23L225.84 36.23Q225.84 38.23 224.40 39.59Q222.96 40.95 220.88 40.95L220.88 40.95Q218.88 40.95 217.52 39.59Q216.16 38.23 216.16 36.23" style={{fill: "rgb(255, 255, 255)"}} transform="translate(-109.400,-20.473)"></path>
                            </g>
                        </g>
                        <text dy="0.35em" fill="#ffffff" font-family="Actor" font-size="80">Knaek.</text>
                    </g>
                </g>
                </svg>
        </div>
    );
}
