import React from 'react';
import SliderHowItWorks from "../../AppCustomizedComponent/SliderHowItWorks";
import {stringsTranslate} from "../../../locales/CustomTranslater";
import {appWords} from "../../../config/Constants";



export default function HowItWorksSectionHome() {

    const initStrings = {
        "heading": stringsTranslate(appWords.student_discount_in_your_city),
        "headingText": "stringsTranslate(appWords.go_through_these_steps)",
        "workData": [
            {
                "id": 1,
                "image": "/assets/images/stap-01.gif",
                "title": stringsTranslate(appWords.step) + ' 1',
                "subtitle": stringsTranslate(appWords.shopping),
                "content": stringsTranslate(appWords.check_the_big_offer_in_nl_be)
            },
            {
                "id": 2,
                "image": "/assets/images/stap-02.gif",
                "title": stringsTranslate(appWords.step) + ' 2',
                "subtitle": stringsTranslate(appWords.knaek_app),
                "content": stringsTranslate(appWords.show_knaek_app_with_digital)
            },
            {
                "id": 3,
                "image": "/assets/images/stap-03.gif",
                "title": stringsTranslate(appWords.step) + ' 3',
                "subtitle": stringsTranslate(appWords.cash_more),
                "content": stringsTranslate(appWords.take_advantage_everytime_from_student_discount)
            }
        ]
    }


    return (
        <section className="section ptb_150 pb-5 mt-5 mt-lg-2 mt-md-2" id={"how-it-works-knaek"}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        {/* Work Content */}
                        <div className="work-content text-center mt-4 w-100">
                            <h1 className="d-none d-md-none d-lg-block">{initStrings.heading}</h1>
                            <h2 className="d-block d-lg-none">{initStrings.heading}</h2>
                            {/*<p className="text-white my-3 mt-sm-4 mb-sm-5">{initStrings.headingText}</p>*/}
                        </div>
                    </div>
                </div>
                <div className="row d-none d-md-none d-lg-block">
                    <div className="col-12 app-works-steps">
                        <div className="row">
                            <div className="col-md-4 col-sm-10 mb-5 pb-5 d-flex align-items-start">
                                {/* Single Work */}
                                <div className="text-center ">
                                    {/* Work Icon */}
                                    <div className="work-icon">
                                        <img src={initStrings.workData[0].image} alt=""/>
                                    </div>
                                    <h4 className="text-knaek-primary">{initStrings.workData[0].title}</h4>
                                    <h3 className="text-knaek-title">{initStrings.workData[0].subtitle}</h3>
                                    <p className="text-knaek-sub">{initStrings.workData[0].content}</p>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-10 d-flex align-items-center">
                                {/* Single Work */}
                                <div className=" text-center">
                                    {/* Work Icon */}
                                    <div className="work-icon">
                                        <img src={initStrings.workData[1].image} alt=""/>
                                    </div>
                                    <h4 className="text-knaek-primary">{initStrings.workData[1].title}</h4>
                                    <h3 className="text-knaek-title">{initStrings.workData[1].subtitle}</h3>
                                    <p className="text-knaek-sub">{initStrings.workData[1].content}</p>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-10 mb-5 pb-5 d-flex align-items-start">
                                {/* Single Work */}
                                <div className=" text-center ">
                                    {/* Work Icon */}
                                    <div className="work-icon">
                                        <img src={initStrings.workData[2].image} alt=""/>
                                    </div>
                                    <h4 className="text-knaek-primary">{initStrings.workData[2].title}</h4>
                                    <h3 className="text-knaek-title">{initStrings.workData[2].subtitle}</h3>
                                    <p className="text-knaek-sub">{initStrings.workData[2].content}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row d-block d-lg-none">
                    <SliderHowItWorks data={initStrings.workData}/>
                </div>
            </div>
        </section>
    );
}
