import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import KnaekPrimaryButton from "./KnaekPrimaryButton";
import { getTrackingLinkSTCall} from "../../api/ApiCalls";
import {hasAValue} from "../../uitls/SharedFunctions";
import {stringsTranslate} from "../../locales/CustomTranslater";
import {appWords} from "../../config/Constants";
import {updateShowAuthModalRS} from "../../redux/reduxActions/reduxStoreActions";
import {useDispatch, useSelector} from "react-redux";

export default function CashbackOfStoreModalComponent({onClose, snipTechOnlineDiscount, deals}) {
    const appLanguageRS = useSelector(state => state.appLanguageRS);
    const authDataRS = useSelector(state => state.authDataNEWRS);
    const dispatch = useDispatch()
    const [intiVariables, setIntiVariables] = useState({})
    let initStrings = {
        important_to_know: stringsTranslate(appWords.important_to_know),
        browser: stringsTranslate(appWords.browser),
        accept_all_cookies_from_the_webshop: stringsTranslate(appWords.accept_all_cookies_from_the_webshop),
        extra_alert_for_amazon: stringsTranslate(appWords.extra_alert_for_amazon),
        use_a_browser_without_ad_blockers: stringsTranslate(appWords.use_a_browser_without_ad_blockers),
        if_you_use_safari: stringsTranslate(appWords.if_you_use_safari),
        do_not_visit_other_online_stores: stringsTranslate(appWords.do_not_visit_other_online_stores),
        fill_your_shopping_cart: stringsTranslate(appWords.fill_your_shopping_cart),
        cashback_warning: stringsTranslate(appWords.cashback_warning),
        to_the_webshop: stringsTranslate(appWords.to_the_webshop),

    }

    useEffect(() => {
        let intiVariablesLocal = {}

        if (hasAValue(snipTechOnlineDiscount)) {
            intiVariablesLocal['commission_groups'] = deals

        }
        setIntiVariables(intiVariablesLocal)

    }, [snipTechOnlineDiscount, appLanguageRS, deals])

    return (
        <Modal className="modal fade radius-15 mt-4" size={"lg"} onHide={onClose} show={true}>
            <div className="radius-15" role="document">
                <div className="radius-15">
                    <div className={"radius-15 pt-3 bg-light"}>
                        <div className="card-header bg-light border-0 d-flex justify-content-end cursor-pointer"
                             onClick={onClose}>
                            <svg className=" mt-3 mr-3" width="15" height="15" viewBox="0 0 32 32">
                                <path fill="black"
                                      d="M31.29,27.48a2.7,2.7,0,0,1-3.81,3.81L16,19.83,4.52,31.29A2.7,2.7,0,0,1,.71,27.48L12.17,16,.71,4.52A2.7,2.7,0,0,1,4.52.71L16,12.17,27.48.71a2.7,2.7,0,0,1,3.81,3.81L19.83,16Z"/>
                            </svg>
                        </div>
                        <div className="modal-body radius-15">
                            <div className="card border-top border-0 mx-2 bg-light">
                                <div className="card-header text-center bg-light border-0 d-flex justify-content-center">
                                    <h1 className="card-title">{initStrings.important_to_know}</h1>
                                </div>
                                <div className="cashback-bg-model pb-3 pt-3 radius-15 ">
                                    <div className="text-left justify-content-center align-content-center radius-15">
                                        <h4 className="mr-5 ml-3">
                                            {initStrings.browser}
                                        </h4>

                                        <ul className="mb-3 ml-4 mr-3">
                                            <li>
                                                {initStrings.accept_all_cookies_from_the_webshop}
                                            </li>
                                            <li>
                                                {initStrings.use_a_browser_without_ad_blockers}
                                            </li>
                                            <li>
                                                {initStrings.if_you_use_safari}
                                            </li>
                                        </ul>
                                        <ul className="mb-3 ml-4 mr-3">
                                            <li className={"pt-2"}>
                                                <span className="font-weight-bold">
                                                    {initStrings.do_not_visit_other_online_stores}
                                                </span>
                                            </li>
                                            <li className={"mt-2"}>
                                                <span className="font-weight-bold">
                                                    {initStrings.fill_your_shopping_cart}
                                                </span>
                                            </li>
                                            <li className={"mt-2"}>
                                                <span className="text-knaek-danger" style={{ fontSize: '14px' }}>
                                                    {initStrings.cashback_warning}
                                                </span>
                                            </li>
                                            {snipTechOnlineDiscount?.title?.toString().toLowerCase().includes("amazon") &&
                                                <li className={"pt-2"}>
                                                <span className="font-weight-bold text-knaek-danger">
                                                    {initStrings.extra_alert_for_amazon}
                                                </span>
                                                </li>}
                                        </ul>

                                    </div>
                                </div>


                            </div>
                            <div className={" border-0 order-0 order-lg-1 d-flex justify-content-center pt-3 pb-3"}>
                                <KnaekPrimaryButton
                                    title={initStrings.to_the_webshop.toString().toUpperCase()}
                                    className={" mt-2"}
                                    onClick={() => goToWebsite()}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );

    async function goToWebsite() {
        if (!hasAValue(authDataRS)) {
            dispatch(updateShowAuthModalRS(true))
        } else {
            try {
                let data = {dealId: snipTechOnlineDiscount.deals[0].id}
                let response = await getTrackingLinkSTCall(data)
                window.open(response.data.redirectUrl, '_blank')
            } catch (error) {
                console.log(error)
            }
        }

    }
}
