import React from 'react';
import {stringsTranslate} from "../../../locales/CustomTranslater";
import {appWords} from "../../../config/Constants";



export default function SnipTechBannerSectionOnlineDiscounts() {


    const initStrings = {
        heading: stringsTranslate(appWords.online_discounts_sl),
        subheading: stringsTranslate(appWords.banner_section_online_discounts_subheading),
        content: stringsTranslate(appWords.banner_section_online_discounts_content),
    }

    return (
        <section className="section align-items-start justify-content-start mb-0">
            <div className="container-fluid">
                <div className="row">
                    <div
                        className="col-lg-2 col-12 d-flex text-center justify-content-left justify-content-lg-center align-items-center">
                        <h2 className="my-2 pt-4">{initStrings.heading}</h2>
                    </div>
                    <div className="col-lg-10 col-12 text-left  mb-0 pb-0">
                        <h5 className="my-2 pt-4">{initStrings.subheading}</h5>
                        <p className="mt-3 mr-5">{initStrings.content}</p>
                    </div>
                </div>
            </div>
        </section>
    );
}
