import React from 'react';
import {Modal} from "react-bootstrap";
import KnaekPrimaryButton from "./KnaekPrimaryButton";
import {stringsTranslate} from "../../locales/CustomTranslater";
import { appWords} from "../../config/Constants";
import ImageComponent from "./ImageComponent";

export default function RedirectToAppModalComponent({closeModal, showModal, icon, title, storeID, link}) {
    let initStrings = {
        to_the_website: stringsTranslate(appWords.to_the_website),
        to_the_app: stringsTranslate(appWords.to_the_app),
    }

    return (
        <Modal className="modal fade radius-15 mt-5" size={"lg"} onHide={closeModal} show={showModal}>
            <div className="radius-15" role="document">
                <div className="radius-15">
                    <div className={"radius-15 bg-light py-3"}>
                        <div className="modal-body radius-15">
                            <div className="card border-top border-0 mx-2">
                                <div
                                    className="card-header text-center bg-light border-0 d-flex justify-content-center">
                                    {icon &&  <ImageComponent src={icon}
                                                    className="col-lg-3 col-md-12 bg-white radius-15 store-discount-details-card-logo shadow"
                                                    alt="" height={98} width={98}
                                    />}
                                </div>
                                <div
                                    className="card-header text-center bg-light border-0 d-flex justify-content-center align-items-center">
                                    {title && <h3 className="card-title">{title}</h3>}
                                </div>
                            </div>

                            <div className={" border-0 order-0 d-flex justify-content-center pb-3"}>
                                <KnaekPrimaryButton
                                    title={initStrings.to_the_app.toString().toUpperCase()}
                                    className={" mt-2"}
                                    onClick={() => {
                                        goToApp()
                                    }}
                                />
                            </div>

                            <div className={"border-0 order-0  d-flex justify-content-center pt-3"}>
                                <a className={"text-lg-left text-center text-dark font-weight-bold cursor-pointer"}
                                   onClick={() => {
                                       closeModal()
                                   }}>
                                    {initStrings.to_the_website.toString().toUpperCase()}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );

    function goToApp() {
        window.location = link + storeID
        setTimeout(() => {
            window.location = '/download-app'
        }, 1500);

    }

}
