import React, { useEffect } from 'react';
import {Animated} from "react-animated-css";
import {stringsTranslate} from "../../locales/CustomTranslater";
import {appWords} from "../../config/Constants";
import {postHitTrackingCall} from "../../api/ApiCalls";
import {tiktokTrackDownload} from "../../uitls/TikTokPixelEvents";
import {facebookTrackDownload} from "../../uitls/FacebookPixelEvents";
import {Helmet} from "react-helmet";


export default function DownloadPage() {
    const initStrings = {
        playImg: "/assets/images/knaek/logoKnaek-white.png",
        download_page_content1: stringsTranslate(appWords.download_page_content1),
        download_page_content2: stringsTranslate(appWords.download_page_content2),
        download_app_sl: stringsTranslate(appWords.download_app_sl),
        image: "/assets/images/Knaek_mockup.webp",
        "iconList": [
            {
                "id": 3,
                "className": "google-play",
                "link": "https://play.google.com/store/apps/details?id=net.evect.knaek",
                "iconClass": "fab fa-google-play  h1"
            },
            {
                "id": 4,
                "className": "app-store",
                "link": "https://apps.apple.com/nl/app/knaek/id547383255?platform=iphone",
                "iconClass": "fab fa-app-store h1"
            }
        ],
        appleStoreLink: process.env.REACT_APP_APPLE_STORE,
        playStoreLink: process.env.REACT_APP_PLAY_STORE,
    }

    useEffect(() => {
        const loadData = async () => {
            const isMobile = () => {
                return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
            };
            if (isMobile()) postHitTrackingCall({event: 'download-app', attributes: 'Mobile'});
            else postHitTrackingCall({event: 'download-app', attributes: 'Laptop'});

            const isIOS = () => {
                return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
            };

            const openPlayStore = async () => {
                await postHitTrackingCall({event: 'play-store', attributes: 'Android'});
                tiktokTrackDownload();
                facebookTrackDownload();
                window.location.href = 'market://details?id=net.evect.knaek';
            };

            const openAppStore = async () => {
                await postHitTrackingCall({event: 'app-store', attributes: 'iOS'});
                tiktokTrackDownload();
                facebookTrackDownload();
                window.location.href=('https://apps.apple.com/app/knaek/id547383255');
            };

            if (isMobile()) {
                if (isIOS()) {
                    openAppStore();
                } else {
                    openPlayStore();
                }
            }
        }
        loadData();
    },[])


    return (
        <section className="bg-overlay h-120vh pt-5">
            <Helmet>
                <title>Knaek - {initStrings.download_app_sl}</title>
            </Helmet>
            <div className="container ">
                <div className="row align-items-center mt-5">
                    {/* Welcome Intro Start */}
                    <div className="col-12 col-md-7 mb-5 mt-2 pt-2 mt-lg-0  mt-md-0 pt-lg-0  pt-md-0">
                        <div className="welcome-intro ">
                            <img className="white-logo-download-style d-none d-sm-block" src={initStrings.playImg} alt="knaek"/>
                            <h4 className="text-white mt-4 pr-md-5 mr-md-5 pr-lg-5 mr-lg-5 d-none d-sm-block">
                                {initStrings.download_page_content1}
                                <a onClick={() => redirectToStores(true)}
                                   className={'text-knaek-regular cursor-pointer'}>Apple App Store </a>
                                {initStrings.download_page_content2}
                                <a onClick={() => redirectToStores(false)}
                                   className={'text-knaek-regular cursor-pointer'}> Google Play Store.</a>
                            </h4>
                            <h2 className="text-white mt-4 pr-md-5 mr-md-5 pr-lg-5 mr-lg-5 d-block d-sm-none text-left">
                                {initStrings.download_page_content1}
                                <a onClick={() => redirectToStores(true)}
                                   className={'text-knaek-regular cursor-pointer'}> Apple App Store </a>
                                {initStrings.download_page_content2}
                                <a onClick={() => redirectToStores(false)}
                                   className={'text-knaek-regular cursor-pointer'}> Google Play Store.</a>
                            </h2>
                            {/*Apple reduxStore link show on sm screen */}
                            <div className="row mt-lg-4 mt-md-4 justify-content-center w-75">

                            <Animated className={"col-3 d-none d-sm-block cursor-pointer"}
                                      animationIn="bounceInLeft"
                                      animationOut="fadeOut" isVisible={true} animationInDuration={1000}
                                      animationInDelay={100}>
                                <a className="badge p-3 bg-white shadow radius-100 "
                                   rel="noopener norefferer"
                                   onClick={() => redirectToStores(true)}
                                   aria-label="Apple Store Button" target='_blank'>
                                    <svg className="" width="35" height="32"
                                         viewBox="0 0 32 32">
                                        <path
                                            d="M4.327,11.8c-2.8,4.7-1,11.8,2,16.1,1.5,2.2,3,4.1,5,4.1h.2a5.6,5.6,0,0,0,2.3-.6,5.787,5.787,0,0,1,2.8-.7,6.7,6.7,0,0,1,2.7.6,4.61,4.61,0,0,0,2.5.7c2.3-.1,3.8-2.2,5-4a17.216,17.216,0,0,0,2.2-4.5h0c.1-.1,0-.2-.1-.3h0a6.9,6.9,0,0,1-4.1-6.2,7,7,0,0,1,3.3-5.8h0c.1,0,.1-.1.1-.2v-.2a7.9,7.9,0,0,0-6-3.2h-.5a10.085,10.085,0,0,0-3.7.9,7.428,7.428,0,0,1-1.8.6,6.711,6.711,0,0,1-1.9-.6,8.38,8.38,0,0,0-3.2-.8h-.1A7.8,7.8,0,0,0,4.327,11.8Z"/>
                                        <path
                                            d="M22.227,0a7.5,7.5,0,0,0-4.8,2.5,6.6,6.6,0,0,0-1.7,5.1.2.2,0,0,0,.2.2h.4a6.2,6.2,0,0,0,4.5-2.3,7.2,7.2,0,0,0,1.7-5.3C22.527.1,22.427,0,22.227,0Z"/>
                                    </svg>
                                </a>
                            </Animated>

                            {/*Play reduxStore link show on sm screen */}
                            <Animated
                                className={"col-3 d-none d-sm-block cursor-pointer"}
                                animationIn="bounceInRight"
                                animationOut="fadeOut" isVisible={true} animationInDuration={1000}
                                animationInDelay={100}>
                                <a className="badge p-3 bg-white shadow radius-100"
                                   rel="noopener norefferer"
                                   onClick={() => redirectToStores(false)}
                                   aria-label="Play Store Button" target='_blank'>
                                    <svg className="" width="32" height="32"
                                         viewBox="0 0 28.26 32">
                                        <path
                                            d="M17.83,14.68,3.47.28A2.77,2.77,0,0,1,6.13.41L22.53,10ZM2.15,1.61a3,3,0,0,0-.28,1.25V29.2a2.89,2.89,0,0,0,.28,1.24L16.51,16ZM3.49,31.75A2.92,2.92,0,0,0,4.68,32a2.8,2.8,0,0,0,1.45-.39L22.54,22l-4.7-4.71ZM28.78,13.6h0l-4.55-2.66L19.16,16l5.05,5.06,4.57-2.67a2.81,2.81,0,0,0,0-4.8Z"
                                            transform="translate(-1.87 0)"/>
                                    </svg>
                                </a>
                            </Animated>
                            </div>
                        </div>


                    </div>


                    <div className="col-12 col-md-5">
                        {/* Welcome Thumb */}
                        <div className="mt-lg-5 mt-md-5 mt-0 pt-0">
                            <img src={initStrings.image} width={450} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

    async function redirectToStores(ios) {
        await postHitTrackingCall({event: ios? 'app-store': 'play-store', attributes: ios? 'iOS': 'Android'});
        tiktokTrackDownload();
        facebookTrackDownload();

        if (ios) {
            window.location.href=('https://apps.apple.com/nl/app/knaek/id547383255?platform=iphone');
        } else {
            window.location.href= ('https://play.google.com/store/apps/details?id=net.evect.knaek');
        }
    }

}

