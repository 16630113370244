import React, {useEffect} from "react";
import FooterSection from "../../components/FooterSection/Footer";
import KnaekContactSection from "../../components/AppSections/KnaekContact/KnaekContactSection";
import {Helmet} from "react-helmet";
import {appWords} from "../../config/Constants";
import {stringsTranslate} from "../../locales/CustomTranslater";


export default function ContactPage() {
    useEffect(() => {
        if (!window.location.hash) {
           window.scrollTo(0, 0);
        }
    }, [])
    const contact_us_faq_sl = stringsTranslate(appWords.contact_us_faq_sl)
    
    return (
        <div className={"homepage-4 pt-5"}>
            <div className={"main"}>
                <Helmet>
                    <title>Knaek - {contact_us_faq_sl}</title>
                    <meta property="description" content={"Op deze pagina beantwoorden we enkele vragen die vaak gesteld worden. Staat het antwoord op je vraag er niet bij, stuur dan een e-mail naar info@knaek.be, of vul het contactformulier in."} />
                    <meta property="keywords" content={"contact, vragen"} />

                    {/* Open Graph Meta Tags */}
                    <meta property="og:title" content="Knaek-contact" />
                    <meta property="og:description" content={"Op deze pagina beantwoorden we enkele vragen die vaak gesteld worden. Staat het antwoord op je vraag er niet bij, stuur dan een e-mail naar info@knaek.be, of vul het contactformulier in."}/>
                    <meta property="og:keywords" content={"contact, vragen"}/>
                    <meta property="og:type" content="website" />

                </Helmet>
                <KnaekContactSection/>
                <FooterSection/>
            </div>
        </div>
    );
}
