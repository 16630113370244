import React from 'react';
import {stringsTranslate} from "../../../locales/CustomTranslater";
import {appLanguage, appWords} from "../../../config/Constants";
import KnaekPrimaryButton from "../../AppComponents/KnaekPrimaryButton";
import KnaekSecondaryButton from "../../AppComponents/KnaekSecondaryButton";
import { useSelector} from "react-redux";
import OrderComponent from "../OrderSections/OrderComponent";
import { useHandleLinkClick } from '../../../hooks/useHandleLinkClick';
import FooterBottomSection from "../../FooterSection/FooterBottom";
import MobileLanguageSwitcher from "../../AppComponents/MobileLanguageSwitcher";

const ContentSection = ({sectionData, language, isPrimary}) => {
    if (!sectionData.showSection) return null;

    const {contentTitle, contentText, contentButtonUrl, contentButtonLabel, showButton} = sectionData;

    const ButtonComponent = isPrimary ? KnaekPrimaryButton : KnaekSecondaryButton;

    return (
        <div className="col-lg-11 col-sm-12 bg-gray px-3">
            <div className="text-center align-items-center mb-5 justify-content-center">
                <div className="mt-5 pt-5">
                    <h1>{language === appLanguage.nl ? contentTitle.nl : contentTitle.en}</h1>
                </div>
                <div className="mb-5">
                    <p>{language === appLanguage.nl ? contentText.nl : contentText.en}</p>
                </div>
                {showButton && contentButtonUrl && (
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <ButtonComponent onClick={() => (window.location.href = contentButtonUrl)}
                                             title={contentButtonLabel}/>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default function IntroKnaekInfoSection({introData}) {
    const appLanguageRS = useSelector((state) => state.appLanguageRS);

    const sections = [
        {
            contentTitle: { nl: introData.content.contentTitle4, en: introData.content.contentTitle4EN },
            contentText: { nl: introData.content.contentText4, en: introData.content.contentText4EN },
            contentButtonUrl: introData.content.contentButtonUrl4,
            contentButtonLabel: introData.content.contentButtonLabel4,
            showSection: introData.content.showSection4,
            showButton: introData.content.showButton4,
        },
    ];

    const initStrings = {
        activation_knaek_app: stringsTranslate(appWords.activation_knaek_app),
        the_student_discount_app: stringsTranslate(appWords.the_student_discount_app),
        discount_for_first_year: stringsTranslate(appWords.discount_for_first_year),
        your_first_year_is_on_us: stringsTranslate(appWords.your_first_year_is_on_us),
        download_the_app: stringsTranslate(appWords.download_the_app),
        ios_app: stringsTranslate(appWords.ios_app),
        android_app: stringsTranslate(appWords.android_app),
        terms_n_conditions: stringsTranslate(appWords.terms_n_conditions),
        about_knaek: stringsTranslate(appWords.about_knaek),
    }

    const handleLinkClick = useHandleLinkClick();

    return (
        <section className="section">
            <div className="container">
                <MobileLanguageSwitcher/>
                <div className="row h-100vh justify-content-center">
                    {sections.map((section, index) => (
                        <ContentSection key={index} sectionData={section} language={appLanguageRS} isPrimary={index === 0} />
                    ))}

                    <div className="col-lg-11 col-sm-12 bg-gray px-3">
                        <div className="mr-lg-5 ml-lg-5 text-center align-items-center mb-5 justify-content-center">
                            <div className="col-12">
                            <div className="row justify-content-center align-content-center">
                                    <table className="col-12 table intro-table border radius-15 bg-white overflow-hidden">
                                        <tbody className="">
                                        <tr>
                                            <th className="text-left">
                                                <span>{initStrings.activation_knaek_app}</span>
                                                <p>{initStrings.the_student_discount_app}</p>
                                            </th>
                                            <td className="text-right">€30</td>
                                        </tr>
                                        <tr>
                                            <th className="text-left">
                                                <span className="text-success">{initStrings.discount_for_first_year}</span>
                                                <p className="text-success">{initStrings.your_first_year_is_on_us} </p>
                                            </th>
                                            <td className="text-right text-success">-€29.98</td>
                                        </tr>
                                        <tr>
                                            <th className="text-left">
                                                <span>{initStrings.to_pay}</span>
                                            </th>
                                            <td className="text-right">€0.02</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="col-12 p-0 spacing">
                                <div className="row justify-content-center text-left">
                                    <OrderComponent backgroundColor={"bg-transparent"} withStudentEmail={false}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-11 col-sm-12 bg-white border-top px-3 pb-5">
                        <img className="pb-4 pt-4 pl-3" style={{width: "96px"}} src="/assets/images/logo-roze.png" alt=""/>

                        <div className="col-12">
                            <div className="row justify-content-between">
                                <div className="col-6 justify-content-start">
                                    <h4>{initStrings.about_knaek}</h4>
                                    <ul>
                                        <li>
                                            <a className={"cursor-pointer"} onClick={() => handleLinkClick("/algemene-voorwaarden")}>{initStrings.terms_n_conditions}</a>
                                        </li>
                                        <li>
                                            <a className={"cursor-pointer"} onClick={() => handleLinkClick("/contact")}>FAQ</a>
                                        </li>
                                    </ul>

                                </div>
                                <div className="col-6 justify-content-start">
                                    <h4>{initStrings.download_the_app}</h4>
                                    <ul>
                                        <li><a href={"https://play.google.com/store/apps/details?id=net.evect.knaek"}>{initStrings.ios_app}</a></li>
                                        <li><a href={"https://apps.apple.com/nl/app/knaek/id547383255?platform=iphone"}>{initStrings.android_app}</a></li>
                                    </ul>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-12"><FooterBottomSection/></div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </section>
    );
}

