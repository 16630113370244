import React from "react";
import {stringsTranslate} from "../../../locales/CustomTranslater";
import {appWords} from "../../../config/Constants";
import KnaekPrimaryButton from "../../AppComponents/KnaekPrimaryButton";
import HowItWorksSectionWhoIsKnaek from "../HomepageSections/HowItWorksSectionWhoIsKnaek";
import { useHandleLinkClick } from "../../../hooks/useHandleLinkClick";



export default function AboutUsIntroText() {
    const handleLinkClick = useHandleLinkClick();


    const initStrings = {
        heading: stringsTranslate(appWords.about_us_heading),
        contentHead1: stringsTranslate(appWords.about_us_content_head1),
        contentBody1: stringsTranslate(appWords.about_us_content_body1),
        contentButton11: stringsTranslate(appWords.knaek_order),
        contentButton12: stringsTranslate(appWords.give_discount_cl),
        contentImg1: "/assets/images/example/341960096_595436639202373_5380615141263090315_n.jpg",
        contentImgWork: "/assets/images/example/IMG_2287.jpg",

        contentHead2: stringsTranslate(appWords.about_us_content_head2),
        contentBody2: stringsTranslate(appWords.about_us_content_body2),
        contentButton21: stringsTranslate(appWords.knaek_order),
        come_work_at_knaek_button: stringsTranslate(appWords.come_work_at_knaek_button),
        contentImg2: "/assets/images/Knaek_mockup.webp",


        contentHead3: stringsTranslate(appWords.about_us_content_head3),
        contentBody31: stringsTranslate(appWords.about_us_content_body3_1),
        contentBody32: stringsTranslate(appWords.about_us_content_body3_2),
        contentBody331: stringsTranslate(appWords.about_us_content_body3_3_1),
        contentBody332: stringsTranslate(appWords.about_us_content_body3_3_2),

        contentButton31: stringsTranslate(appWords.knaek_order),
        contentButton32: stringsTranslate(appWords.download_knaekapp_cl),
        contentImg3: "/assets/images/example/Scherm­afbeelding 2023-06-21 om 14.34.05.png",

        playImage: "/assets/images/about_us1.png",

    }

    return (
        <div id="screenshots" className="section mt-5 partner-section w-100 mb-5">
            <div className="container">
                <div className="row">
                    <div className={"col-lg-6 col-md-6 col-sm-10 "}>
                        <h1 className="pt-4 px-4">{initStrings.contentHead1}</h1>
                        <div className="text-md-left mb-5 text-lg-left align-content-center">
                            <p className="mt-3 px-4" dangerouslySetInnerHTML={{__html: initStrings.contentBody1}}></p>
                            {/*<div className="d-flex justify-content-around align-items-center mt-4 pt-2">*/}
                            {/*    <KnaekPrimaryButton*/}
                            {/*        title={initStrings.contentButton11}*/}
                            {/*        className={" "}*/}
                            {/*        onClick={()=> window.location.href= "/bestellen"}*/}
                            {/*    />*/}
                            {/*    <KnaekPrimaryButton*/}
                            {/*        title={initStrings.contentButton12}*/}
                            {/*        className={" "}*/}
                            {/*        onClick={()=> window.location.href= "/studentenkorting-geven"}*/}
                            {/*    />*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-10 text-md-left mb-4 text-lg-left align-content-center">
                        <img src={initStrings.contentImg1} alt={""} className="radius-15"></img>
                    </div>
                </div>

                <div className="row align-items-center" id={"knaek-app-works"}>
                    <div className="col-lg-6 col-md-6 col-sm-10 text-md-left mb-5 text-lg-left align-content-center d-md-block d-none mt-5">
                        <section className={"row justify-content-center align-items-center"}>
                            <img src={initStrings.contentImg2} alt={""} className=" knaek-app-img-about-us"></img>
                        </section>
                    </div>
                    <div className={"col-lg-6 col-md-6 col-sm-10 "}>
                        <h1 className="pt-4 px-4">{initStrings.contentHead2}</h1>
                        <div className="text-md-left mb-5 text-lg-left align-content-center">
                            <p className="mt-3 px-4" dangerouslySetInnerHTML={{__html: initStrings.contentBody2}}></p>
                        </div>
                    </div>
                </div>

                <HowItWorksSectionWhoIsKnaek/>


                <div className="row mt-5">
                    <div className={"col-lg-6 col-md-6 col-sm-10 "}>
                        <h1 className="pt-4 px-4">{initStrings.contentHead3}</h1>
                        <div className="text-md-left mb-5 text-lg-left align-content-center">
                            <p className="mt-3 font-weight-bolder px-4">{initStrings.contentBody31}</p>
                            <p className="mt-3 px-4" dangerouslySetInnerHTML={{__html: initStrings.contentBody32}}></p>
                            <p className="mt-3 px-4">{initStrings.contentBody331} </p>
                            <div className="d-flex justify-content-around align-items-center mt-4 pt-2">

                                <KnaekPrimaryButton
                                    title={initStrings.come_work_at_knaek_button}
                                    className={" "}
                                    onClick={()=> handleLinkClick("/vacatures")}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-10 text-md-left mb-4 text-lg-left align-content-center">
                        <img src={initStrings.contentImgWork} alt={""} className="radius-15"></img>
                    </div>
                </div>
            </div>
        </div>
    );
}
