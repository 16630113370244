import React, {useState} from 'react';
import KnaekPrimaryButton from "../../components/AppComponents/KnaekPrimaryButton";
import {registerFreeDistributionCall} from "../../api/ApiCalls";
import {failedAlertFunction, successAlertFunction} from "../../uitls/AlertFunctions";
import {stringsTranslate} from "../../locales/CustomTranslater";
import {appWords} from "../../config/Constants";
import { useHandleLinkClick } from '../../hooks/useHandleLinkClick';
import {Helmet} from "react-helmet";


export default function FreeDistributionPage() {
    const [email, setEmail] = useState(undefined)
    const handleLinkClick = useHandleLinkClick();

    const initString = {
        there_is_no_member_with_this: stringsTranslate(appWords.there_is_no_member_with_this),
        the_code_has_been_send: stringsTranslate(appWords.the_code_has_been_send),
        email_distribution_already_registered: stringsTranslate(appWords.email_distribution_already_registered),
        sorry: stringsTranslate(appWords.sorry),
        thank_you: stringsTranslate(appWords.thank_you),
        request_free_knaek_id: stringsTranslate(appWords.request_free_knaek_id),
        if_you_are_student_at_participating_vocational_institution: stringsTranslate(appWords.if_you_are_student_at_participating_vocational_institution),
        your_student_email_address: stringsTranslate(appWords.your_student_email_address),
        request: stringsTranslate(appWords.request),
    }


    return (
        <section className="">
            <Helmet>
                <title>Knaek - {initString.request_free_knaek_id}</title>
            </Helmet>
            <div className="container-fluid homepage-4 pt-5 mt-5 h-100vh bg-knaek-gray-light">
                <div className="row mt-5 pt-5 justify-content-center align-content-center align-items-center ">
                    <div className="col-lg-6 col-md-6 col-sm-10 mt-5">
                        <div>
                            <ul className="list-group list-group-flush pl-4 pr-4">
                                <li className="row justify-content-center radius-15 bg-transparent border-0 mt-3">
                                    <img src={'/assets/images/logoKnaek.png'} width={200} alt=""/>
                                </li>

                                <li className="row justify-content-center radius-15 bg-transparent border-0 mt-3">
                                    <h4 className={"text-center"}>{initString.request_free_knaek_id}</h4>
                                </li>

                                <li className="row justify-content-center radius-15 bg-transparent border-0 mt-3">
                                    <p className={"text-center pl-md-5 pr-md-5 pl-lg-5 pr-lg-5 pl-0 pr-0"}>
                                        {initString.if_you_are_student_at_participating_vocational_institution}</p>
                                </li>

                                <li className="row justify-content-center radius-15  border-0 mt-3">
                                    <input name="email" type="email"
                                           onChange={val => setEmail(val.target.value)}
                                           className="form-control shadow-none ml-md-5 mr-md-5 ml-lg-5 mr-lg-5"
                                           placeholder={initString.your_student_email_address}
                                    />
                                </li>

                                <li className="row justify-content-center radius-15 border-0 mt-2">
                                    <KnaekPrimaryButton name="feedback" title={"✔ "+initString.request}
                                                        onClick={() => registerFreeDistribution()}
                                                        className={"mt-2 justify-content-center shadow mb-5 border-0 "}
                                    />
                                </li>

                            </ul>
                        </div>

                    </div>

                </div>
            </div>
        </section>
    );

    async function registerFreeDistribution() {
        try {
            let data = await registerFreeDistributionCall({email})
                .then(result => {
                    successAlertFunction(initString.thank_you, initString.the_code_has_been_send);
                    // Redirect to the home page after a short delay
                    setTimeout(() => {
                        handleLinkClick('/');
                    }, 2000); // Adjust the delay as needed
                })
        } catch (e) {
            if (e.response && e.response.status === 409) {
                failedAlertFunction(initString.sorry, initString.email_distribution_already_registered);
            } else {
                failedAlertFunction(initString.sorry, initString.there_is_no_member_with_this);
            }
        }
    }
}
