import React, {useState} from 'react';
import AccordionItemKnaekVacancy from "../../AppCustomizedComponent/AccordionItemKnaekVacancy";


const initStrings = {
    "faqData": [

        {
            "id": 1,
            "title": "Fulltime: Account-en Regiomanager",
            "header1": "ACCOUNT- EN REGIOMANAGER, AMSTERDAM",
            "vacancy_content": [
                {
                    "section": "per direct (of later in overleg) "
                },
                {
                    "section": "Als je net bent afgestudeerd aan het hoger onderwijs of de universiteit, maar eigenlijk nog geen afscheid wilt nemen van jouw studentenleven, dan is dit voor jou de ideale start van je carrière. Ontdek hier wat voor moois jou als Account- en Regiomanager te wachten kan staan!"
                },
                {
                    "header": "Functieomschrijving",
                    "section": "Als Account- en Regiomanager van Knaek ben je verantwoordelijk voor een regio van 9 studentensteden. Je beheert en werft zowel lokale als landelijke partnerships en accounts. Daarnaast doe je ervaring op in onder andere sales, marketing en communicatie, account- en projectmanagement, grafisch ontwerpen, en (online) marketing. Ook geef je input voor verbeteringen ten behoeve van de gebruiks- en klantervaring in wekelijkse brainstormsessies op kantoor."
                },
                {

                    "section": "Al meteen vanaf de start krijg je veel ruimte om eigen ideeën in te brengen en het netwerk te gebruiken dat je tijdens je studiejaren hebt opgebouwd. Daarnaast is er ruimte voor het opzetten van nieuwe projecten. Er wordt veel waarde gehecht aan jouw inbreng en je hebt de mogelijkheid om je te ontwikkelen in richtingen die jou interesseren en tegelijk het bedrijf ten goede komen. Maar wees gewaarschuwd: wat je hier in je 1e jaar gaat leren, doe je bij een ander bedrijf in 3 jaar.\n"

                },
                {
                    "section": "Klinkt dit allemaal als muziek in de oren? Stuur dan direct een e-mail terug met je motivatiebrief en CV. Nog niet zeker of dit bij je past? Lees dan verder."
                },
                {
                    "header": "Waar zijn wij naar op zoek?",
                    "section": "● Je bent <3 jaar afgestudeerd aan de universiteit of binnen het hoger onderwijs.\n"
                },
                {
                    "section": "● Je hebt tijdens je studie actief deelgenomen aan het studentenleven."
                },
                {
                    "section": "● Je kunt goed samenwerken in teamverband, maar vindt het ook prima om zelf op pad te gaan."
                },
                {
                    "section": "● Je hebt ervaring in het maken van professionele sales gesprekken (al gaat een vlotte babbel je ook zeker verder brengen)."
                },
                {
                    "section": "● Dus, ben jij klaar met je studie en kan je bovenstaande profieleisen ook afvinken? Dan zijn wij op zoek naar jou!"
                },
                {
                    "header": "Wat kan je van ons verwachten?\n",
                    "section": "● Een jong team (4 FTE tussen de 23 en 27 jaar) en een aangename werksfeer.\n"

                },
                {
                    "section": "● Iedere dag gratis lunch en op vrijdag een gezellige borrel."
                },
                {
                    "section": "● Een zelfstandige en gevarieerde functie van 40 uur in de week."
                },
                {
                    "section": "● Salaris van €3,500 bruto per maand."
                },
                {
                    "section": "● 25 vakantiedagen + 13 ATV (dat zijn extra vrije dagen)."
                },
                {
                    "section": "● Reiskostenvergoeding en 1e klas DalVrij-treinabonnement."
                },  {
                    "section": "● Macbook Pro abonnement met onbeperkt bellen en internet."
                },
                {
                    "section": "● Een fysieke werkplek in het centrum van Amsterdam."
                },
                {
                    "header": "Startdatum: per direct (of later in overleg)\n",
                    "section": "Ben je door deze vacature helemaal getriggerd? Stuur dan je CV + motivatie naar info@knaek.nl. Mocht je nog vragen hebben, dan kun je ons natuurlijk altijd even bellen op +31 85 130 5294.\n"
                },
            ],
            "button": "Solliciteer nu",
            "button_link": "/contact"

        },
        {
            "id": 3,
            "title": "Meewerkstage Amsterdam | €350 p/m",
            "header1": "MARKETING & SALES MEEWERKSTAGE | AMSTERDAM",
            "vacancy_content": [
                {
                    "section": "Commerciële Economie | Bedrijfskunde e.d."
                },
                {
                  "section": "Knaek is al twaalf collegejaren lang de expert op het gebied van studentenkortingen. Studentenkortingen zijn bij Knaek niet zomaar iets. We steken enorm veel tijd, energie en passie in het werven van de allerbeste studentenkortingen. Ons doel: (mede)studenten zoveel mogelijk geld laten besparen op alledaagse uitgaven."
                },
                {
                    "header": "Over Knaek",
                    "section": "Knaek wordt gerund door starters op de arbeidsmarkt. Zo staan we áltijd dicht bij onze doelgroep. Immers, een bedrijf dat zich op jongeren richt moet zelf ook jong zijn. Toch? ;-) Naast jouw zes collega’s op het hoofdkantoor, werken door het jaar heen meer dan vierhonderd studenten aan Knaek."
                },
                {
                    "header": "Waarom een (meewerk)stage bij Knaek?",
                    "section": "Wij zijn zelf ook als stagiair begonnen bij Knaek en kunnen je daarom best goed vertellen wat een stage bij Knaek zo leuk en leerzaam maakt. Juist omdat we met een klein vast team de gehele organisatie aansturen maak je tijdens je stage kennis met heel veel verschillende aspecten van bedrijfsvoering. Tijdens jouw stage staat jouw persoonlijke ontwikkeling voorop. Dat betekent dat je hier niet alleen klusjes aan het uitvoeren bent, maar echt iets gaat leren over sales, (online) marketing en nog veel meer. Je krijgt je eigen project, waarvoor je volledig verantwoordelijk bent. Solliciteer daarom alleen voor een meewerkstage bij Knaek als je écht uitgedaagd wilt worden."
                },
                {
                    "header": "Wat zoeken we in jou?",
                    "section": "● Je bent commercieel ingesteld en wilt na jouw afstuderen ook in het commerciële werkveld aan de slag"
                },
                {
                    "section": "● Je neemt actief deel aan het studentenleven, je woont bijvoorbeeld in een studentenstad en bent lid van een studie- of studentenvereniging (ervaring met commissies is een pré)"

                },
                {
                    "section": "● Je kunt goed samenwerken in teamverband, maar vindt het ook prima om zelfstandig te werken"

                },
                {
                    "section": "● Je bent ondernemend en toont initiatief, jij wilt ons helpen om Knaek nóg relevanter te maken voor alle studenten in het MBO, HBO en WO-onderwijs"

                },
                {
                    "header": "Wat krijg jij van ons?",
                    "section": "● Een toffe en leerzame stage waarin je wordt uitgedaagd"
                },
                {
                    "section": "● Mogelijkheid om na jouw stage part-time bij ons in dienst te blijven om je eigen opgezette project(en) door te ontwikkelen"

                },
                {
                    "section": "● Een prima stagevergoeding van €350,- netto per maand"

                },
                {
                    "section": "● Persoonlijke begeleiding en coaching"

                },
                {
                    "section": "● Iedere dag een gratis lunch en op vrijdag een gezellige borrel"

                },
                {
                    "section": "● Ben je door deze vacature helemaal getriggerd? Stuur dan je CV + motivatie naar info@knaek.nl. Mocht je nog vragen hebben, dan kun je ons natuurlijk altijd even bellen op 020 – 716 57 71."

                },
            ],
            "button": "Solliciteer nu",
            "button_link": "/contact"
        },


    ],
}


export default function VacancysType() {
    const [isExpanded, setIsExpanded] = useState(null);

    const toggleCollapse = (id) => {
        if (isExpanded === id) {
            setIsExpanded(null);
        } else {
            setIsExpanded(id);
        }
    };

    return (
        <section className="section faq-area ptb_100">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="accordion">
                            <div className="row justify-content-center radius-10">
                                <div className="col-12 col-md-10 col-lg-12">
                                    {initStrings.faqData.map(({title, header1, vacancy_content, button, button_link}, idx) => (
                                        <AccordionItemKnaekVacancy
                                            key={idx}
                                            title={title}
                                            vacancyContent={vacancy_content}
                                            header1={header1}
                                            button={button}
                                            buttonLink={button_link}
                                            isExpanded={isExpanded}
                                            toggleCollapse={toggleCollapse}
                                            idx={idx}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

