import { useEffect } from 'react';

const HostnameRedirect = () => {

    useEffect(() => {
        const hostname = window.location.hostname;

        const redirects = {
            'download.knaek.nl': 'https://www.knaek.nl/download-app',
            'bestellen.knaek.nl': 'https://www.knaek.nl/bestellen',
            'bestellen.knaek.com': 'https://www.knaek.nl/bestellen',
            'cashback.knaek.nl': 'https://www.knaek.nl/cashback/studentenkorting',
            'cashback.knaek.com': 'https://www.knaek.nl/cashback/studentenkorting'
        };

        if (redirects[hostname]) {
            window.location.href = redirects[hostname];
        }
    }, []);

    return null; // Render nothing; this is just a redirection mechanism
};

export default HostnameRedirect;
